dwc-header, dwc-mini-header {
  background-color: #fff;
}

dwc-header[theme='dark'], dwc-mini-header[theme='dark'] {
  background-color: #212529;
}

dwc-header {
  display: block;
  height: 57px;
}
dwc-header[border-bottom='none'] {
  height: 56px;
}

@media (min-width: 992px) {
  dwc-header {
    height: 105px;
  }
  dwc-header[border-bottom='none'] {
    height: 104px;
  }
}

dwc-header[thin] {
  height: 57px;
}
dwc-header[thin][border-bottom='none'] {
  height: 56px;
}

dwc-mini-header {
  display: block;
  height: 51px;
}
dwc-mini-header[border-bottom='none'] {
  height: 50px;
}

dwc-mini-header[thin] {
  height: 41px;
}
dwc-mini-header[thin][border-bottom='none'] {
  height: 40px;
}

.dwc-header-below-fixed-block {
  top: 57px;
}

.dwc-header-no-border-below-fixed-block {
  top: 56px;
}

@media (min-width: 992px) {
  .dwc-header-below-fixed-block, .dwc-header-no-border-below-fixed-block {
    top: 0;
  }
}
